import React, { useRef } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { GoogleLogin } from '@react-oauth/google';

const Login = () => {
  const contactUsRef = useRef(null);
  const navigate = useNavigate();

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const response = await fetch('http://localhost:8888/.netlify/functions/authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credentialResponse.credential }),
      });
  
      const data = await response.json();
  
      if (response.ok) { 
        localStorage.setItem('token', data.token); 
        navigate('/');
      } else {
        console.error('Google login failed', data.message);
      }
    } catch (error) {
      console.error('Google login error:', error);
    }
  };
  
  return (
    <div className='login-container'>
      <Navbar contactUsRef={contactUsRef} />
      <div className='login-main-container'>
        <div className='login-header'>Login</div>

        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={() => console.log('Login Failed')}
        />

        <span className='register-or'>OR</span>
        <div className='recovery-instructions1'>
          Please enter your e-mail and password
        </div>
        <form action='' className='login-form'>
          <input type='email' placeholder='Email Id' className='login-email-input' />
          <input type='text' placeholder='Password' className='pass-input' />
          <button className='login-submit-button' onClick={() => navigate('/')}>
            Login
          </button>
        </form>
        <div className='recovery-instructions1'>
          Don't have an account?{' '}
          <span>
            <button className='create-button' onClick={() => navigate('/register')}>
              Create one
            </button>
          </span>
        </div>
      </div>
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  );
};

export default Login;
