import React,{useRef,useState} from 'react';
import './WomensBags.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar.jsx';
import ContactUs from '../../../components/ContactUs/ContactUs.jsx';
import WMimg from '../../../assets/Images/womensbagscat.webp';
import olivaimg from '../../../assets/Images/Oliva.jpg';

const WomensBags = () => {
  const contactUsRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [isLaptopScreen, setIsLaptopScreen] = useState(window.innerWidth >= 768);

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`, {
      state: { category: "Women's Bag" }
    });
  };
  return (
    <div className='wm-container'>
      <Navbar contactUsRef={contactUsRef}/>
      <div className="wm-main-container">
      {!isLaptopScreen && (
    <>
      <div className='image-area'>
        <img src={WMimg} alt="Accessories" />
      </div>
      <div className="a-header">
        WOMEN'S BAGS
      </div>
    </>
  )}
  {isLaptopScreen && (
    <>
    <div className="a-header-desktop">
    WOMEN'S BAGS
      </div>
    
      <div className='image-area'>
        <img src={WMimg} alt="Accessories" />
      </div>
      </>
  )}
      <div className="tagline">
      "Timeless Elegance, Crafted for the Modern Woman."
      </div>
      {/* <div className="Discount-line">
        *<span className='b-disc'>30%</span> Discount on all the products
      </div> */}
      <div className="product-area">
        <div className="a-div1">
          <div className='product' onClick={() => handleProductClick('SL108')}>
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688072/purse_blue_1_pzqbdc.webp" alt="" className='product-img2'/>
            <div className='product-title'>
                  <div className='product-name'>Handle Bag</div>
                  
                  <div className='prices'>₹ 3045 <span className='cut-price1'>₹ 4350</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
          <div className='product' onClick={() => handleProductClick('SL186')}>
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688072/purse_3_red_dgxvlm.webp" alt="" className='product-img2'/>
            <div className='product-title'>
                  <div className='product-name'>Bucket Bag</div>
                  
                  <div className='prices'>₹ 3854 <span className='cut-price1'>₹ 5505</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
        </div>
        <div className="a-div1">
        <div className='product' onClick={() => handleProductClick('SL164')}>
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688071/purse_blue3_akhujd.webp" alt="" className='product-img2'/>
            <div className='product-title'>
                  <div className='product-name'>Eco Shiny Tote</div>
                  
                  <div className='prices'>₹ 1848 <span className='cut-price1'>₹ 2640</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
          <div className='product' onClick={() => handleProductClick('SL177')}> 
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688071/womenpurse4_p7sba8.webp" alt="" className='product-img2'/>
            <div className='product-title'>
                  <div className='product-name'>Big Armani Bag</div>
                  
                  <div className='prices'>₹ 3308  <span className='cut-price1'>₹ 4725</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product' onClick={() => handleProductClick('SL147')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688055/purse_uwzh3s.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Women's Clutch</div>
                    
                    <div className='prices'>₹ 1628 <span className='cut-price1'>₹ 2325</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL101')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688059/sidebag_browm_8_fcjirx.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Side Bag</div>
                    
                    <div className='prices'>₹ 1685 <span className='cut-price1'>₹ 2450</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product'onClick={() => handleProductClick('SL162')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688055/sideblue_4_ivt8cg.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Cross Body Sling</div>
                    
                    <div className='prices'>₹ 1785 <span className='cut-price1'>₹ 2550</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL105')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1723200957/IMG_5939_dok3yn.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Triangle Sling Bag</div>
                    
                    <div className='prices'>₹ 2153 <span className='cut-price1'>₹ 3075</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product'onClick={() => handleProductClick('SL163')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688054/SL105_atas6i.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Square Ladies Laptop Bag</div>
                    
                    <div className='prices'>₹ 3906 <span className='cut-price1'>₹ 5580</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL184')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688054/SL177_3_p5h8ev.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Versatile Women's Bag</div>
                    
                    <div className='prices'>₹ 3938  <span className='cut-price1'>₹ 5625</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product'onClick={() => handleProductClick('SL102')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722709037/WhatsApp_Image_2024-08-03_at_23.38.00_1_cxnxzj.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Baddel Habdel</div>
                    
                    <div className='prices'>₹ 3969 <span className='cut-price1'>₹ 5670</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL103')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722709021/WhatsApp_Image_2024-08-03_at_23.37.55_1_sqdhyf.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Small Armani Bag</div>
                    
                    <div className='prices'>₹ 2415  <span className='cut-price1'>₹ 3450</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product'onClick={() => handleProductClick('SL128')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722709048/WhatsApp_Image_2024-08-03_at_23.37.54_okfzbg.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Round Japan Handbag</div>
                    
                    <div className='prices'>₹ 3833 <span className='cut-price1'>₹ 5475</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL148')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722709029/WhatsApp_Image_2024-08-03_at_23.37.58_cgjeas.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Hermen's Bag</div>
                    
                    <div className='prices'>₹ 3759  <span className='cut-price1'>₹ 5370</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product'onClick={() => handleProductClick('SL165')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722709042/WhatsApp_Image_2024-08-03_at_23.38.03_srgdet.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Yoshi 2</div>
                    
                    <div className='prices'>₹ 2436 <span className='cut-price1'>₹ 3480</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL192')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722709006/WhatsApp_Image_2024-08-03_at_23.38.00_dauzrh.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Sling With Chain</div>
                    
                    <div className='prices'>₹ 1838 <span className='cut-price1'>₹ 2625</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>
        <div className="a-div1">
          <div className='product'onClick={() => handleProductClick('SL141')}>
              <img src={olivaimg} alt="" className='product-img'/>
              <div className='product-title'>
                    <div className='product-name'>Oliva</div>
                    
                    <div className='prices'>₹ 1575 <span className='cut-price1'>₹ 2250</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL140')}>
              <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688058/handbag_4_gtfc17.webp" alt="" className='product-img2'/>
              <div className='product-title'>
                    <div className='product-name'>Ladies Sling Stan BSL</div>
                    
                    <div className='prices'>₹ 1838 <span className='cut-price1'>₹ 2625</span>/- <span className='diff-disc1'>30%OFF</span></div>
              </div>
          </div>
        </div>

     

      </div>
      </div>
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  )
}

export default WomensBags
