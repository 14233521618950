import { HStack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import './cart.css';

// Function to format price
export function formatPrice(value, opts = {}) {
  const { locale = 'en-US', currency = 'INR' } = opts;
  
  const formatter = new Intl.NumberFormat(locale, {
    currency: currency,
    style: 'currency',
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export const PriceTag = (props) => {
  const { price, currency = 'INR', cutprice } = props;

  // Parse the price and cut price strings
  const parsedPrice = parsePrice(price);
  const parsedCutPrice = parsePrice(cutprice);

  return (
    <HStack spacing="1">
      {/* Display the cut price with a line-through */}
      {parsedCutPrice && (
        <Price isOnSale>
          {formatPrice(parsedCutPrice, { currency })} {/* Formatted original (cut) price */}
        </Price>
      )}
      {/* Display the actual price */}
      <SalePrice mx="2">
        {formatPrice(parsedPrice, { currency })} {/* Formatted sale price */}
      </SalePrice>
    </HStack>
  );
};

const Price = (props) => {
  const { isOnSale, children } = props;
  const defaultColor = mode('gray.700', 'gray.400');
  const onSaleColor = mode('gray.400', 'gray.700');
  const color = isOnSale ? onSaleColor : defaultColor;
  
  return (
    <Text
      as="span"
      fontWeight="medium"
      color={color}
      textDecoration={isOnSale ? 'line-through' : 'none'}  // Line-through for cut price
    >
      {children}
    </Text>
  );
};

const SalePrice = (props) => (
  <Text as="span" fontWeight="semibold" color={mode('gray.800', 'gray.100')} {...props} />
);

// Function to parse price
const parsePrice = (price) => {
  if (typeof price === 'string') {
    // Remove non-numeric characters (e.g., Rs, /-)
    return parseFloat(price.replace(/[^0-9.]/g, ''));
  }
  return price;
};
