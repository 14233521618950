import {
  Box,
  Stack,
  Image,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import './cart.css';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../pages/ProductDescription/UseCart';
import {useEffect} from 'react';

export const CartProductMeta = (props) => {
  const { cartData } = useCart();
  const {image, name, id, category, cutprice} = props; // Add `id` to props
  const navigate = useNavigate();

  // useEffect(()=>{
  //   console.log(image, name, id, category, cutprice);
  // },[]);


  // Function to handle product click, navigating to the product description page
  const handleProductClick = (productId) => {
    // console.log(cartData);
    navigate(`/products/${productId}`, {
      state: { category: category }
      
  });
    console.log('category:',cartData[0].category);
    console.log(`Navigated to product with id: ${productId}`);
  };

  return (
    <Stack direction="row" spacing="5" width="full" className='product-info-cart'>
      <Image
        rounded="lg"
        width="120px"
        height="120px"
        fit="contain"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
        className='product-image-cart'
        onClick={() => handleProductClick(id)}  
      />
      <Box pt="4">
        <Stack spacing="0.5">
          {/* Clicking on product name also navigates to product page */}
          <Text
            fontWeight="medium"
            className='cart-product-data'
            onClick={() => handleProductClick(id)}
            // onClick={() => handleProductClick(id)} // Pass correct `id` here
          >
            {name}
          </Text>
          
          {/* <Text color={mode('gray.600', 'gray.400')} fontSize="sm" className='cart-product-data'>
            {description}
          </Text> */}
        </Stack>
      </Box>
    </Stack>
  );
};
