import { CloseButton, Flex, Link, Select, useColorModeValue } from '@chakra-ui/react';
import { PriceTag } from './PriceTag';
import { CartProductMeta } from './CartProductMeta';
import './cart.css';
import { useCart } from '../../pages/ProductDescription/UseCart';

const QuantitySelect = (props) => {
  return (
    <div>
      Quantity
      <Select
        maxW="64px"
        aria-label="Select quantity"
        focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
        marginTop="0.5rem"
        {...props}
      >
        {[1, 2, 3, 4].map((qty) => (
          <option key={qty} value={qty}>
            {qty}
          </option>
        ))}
      </Select>
    </div>
  );
};

export const CartItem = ({ id, pname, quantity, price, mainImage, category, cut_price }) => {
  const { removeFromCart, updateQuantity, cartData } = useCart();

  const handleQuantityChange = (newQuantity) => {
    updateQuantity(id, newQuantity); // Update quantity via hook
  };

  const parsePrice = (price) => {
    if (typeof price === 'string') {
      return parseFloat(price.replace(/[^\d.-]/g, ''));
    }
    return price;
  };


  const cleanedPrice = parsePrice(price);

  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
      <CartProductMeta name={pname} image={mainImage} id={id} category={category} cutprice={cut_price}/>

      {/* Desktop view */}
      <Flex width="full" justify="space-between" display={{ base: 'none', md: 'flex' }} className="cart-product-data">
        <QuantitySelect value={quantity} onChange={(e) => handleQuantityChange(+e.currentTarget.value)} />
            <PriceTag price={cleanedPrice} cutprice={cut_price} currency="INR" />
        <CloseButton aria-label={`Delete ${pname} from cart`} onClick={() => removeFromCart(id)} />
      </Flex>

      {/* Mobile view */}
      <Flex mt="4" align="center" width="full" justify="space-between" display={{ base: 'flex', md: 'none' }} className="cart-product-data">
        <Link fontSize="sm" textDecor="underline" onClick={() => removeFromCart(id)}>
          Remove
        </Link>
        <QuantitySelect value={quantity} onChange={(e) => handleQuantityChange(+e.currentTarget.value)} />
        <PriceTag price={cleanedPrice} cutprice={cut_price} currency="INR" />
      </Flex>
    </Flex>
  );
};
