import React,{useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Giftwares.css';
import GFimg from '../../../assets/Images/giftwarescat.webp';
import Navbar from '../../../components/Navbar/Navbar.jsx';
import ContactUs from '../../../components/ContactUs/ContactUs.jsx';

const Giftwares = () => {
  const contactUsRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [isLaptopScreen, setIsLaptopScreen] = useState(window.innerWidth >= 768);

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`, {
      state: { category: 'Giftware' }
    });
  };
  return (
    <div className='gf-container'>
      <Navbar contactUsRef={contactUsRef}/>
      <div className="gf-main-container">
      {!isLaptopScreen && (
    <>
      <div className='image-area'>
        <img src={GFimg} alt="Accessories" />
      </div>
      <div className="a-header">
      GIFTWARES
      </div>
    </>
  )}
  {isLaptopScreen && (
    <>
    <div className="a-header-desktop">
        GIFTWARES
      </div>
    
      <div className='image-area'>
        <img src={GFimg} alt="Accessories" />
      </div>
      </>
  )}
      <div className="tagline">
      "Give the Gift of Luxury - Thoughtful Leather Treasures."
      </div>
      {/* <div className="Discount-line">
        *<span className='b-disc'>30%</span> Discount on all the products
      </div> */}
      <div className="product-area">
        <div className="a-div1">
          <div className='product' onClick={() => handleProductClick('SL116')}>
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688061/SL116_6_dlp0s4.webp" alt="" className='product-img'/>
            <div className='product-title'>
                  <div className='product-name'>Pen Stand</div>
                  <div className='prices'>₹ 914 <span className='cut-price1'>₹ 1305</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
          <div className='product'onClick={() => handleProductClick('SL144')}>
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688064/SL144_n453ef.webp" alt="" className='product-img'/>
            <div className='product-title'>
                  <div className='product-name'>Jewelery Box</div>
                  <div className='prices'>₹ 4515 <span className='cut-price1'>₹ 6450</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
        </div>
        <div className="a-div1">
        <div className='product'onClick={() => handleProductClick('SL176')}>
            <img src="https://res.cloudinary.com/dyyocxffd/image/upload/v1722688062/SL176_4_ox5wep.webp" alt="" className='product-img'/>
            <div className='product-title'>
                  <div className='product-name'>Watch Box</div>
                  <div className='prices'>₹ 1313 <span className='cut-price1'>₹ 1875</span>/- <span className='diff-disc1'>30%OFF</span></div>
            </div>
          </div>
         
        </div>

      </div>
      </div>
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
    </div>
  )
}

export default Giftwares;
